import Card from 'react-bootstrap/Card';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import Spinner from './Spinner';
import {
    phdApplicationDocumentsUpload,
    getPhdUploadDocuments,
    deletePhdApplicationDoc,
    phdApplicationPaymentStatus
} from './services';

function PhdDocumentsUpload() {
    const [file, Setfile] = useState();
    const [docname, Setdocname] = useState('');
    const [uploadedDoc, SetUploadedDoc] = useState([]);
    const [spinner, setspinner] = useState(false);
    const navigate = useNavigate();

    const docnameChange = (event) => {
        Setdocname(event.target.value);
    };

    React.useEffect(() => {
        getUploadedDocuments();
    }, []);

    const handleFileInputChange = (event) => {
        const { target: { files } } = event;
        if (!files[0]) {
            return;
        }

        Setfile(files[0]);
    };

    const handleUpload = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', docname);
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('regNo'));
                await phdApplicationDocumentsUpload(formData);
                getUploadedDocuments();
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function getUploadedDocuments() {
        const doc = await getPhdUploadDocuments({ applicationNo: localStorage.getItem('regNo') });
        SetUploadedDoc(doc);
    };

    const deletefile = async (filedata) => {
        try {
            setspinner(true);
            await deletePhdApplicationDoc({ filedata, applicationNo: localStorage.getItem("regNo") });
            getUploadedDocuments();
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    const viewfile = (filedata) => {
        if (filedata.filePath) {
            window.open(process.env.REACT_APP_SERVICE_URL + "/documents/" + filedata.filePath, "_blank");
        }
        else {
            swal({
                title: "File Not Found.",
                icon: 'info'
            });
        }
    };

    const goToPreviousPage = () => {
        navigate("/phdapplication");
    };

    const nextPage = async () => {
        try {
            setspinner(true);
            const result = await phdApplicationPaymentStatus({ applicationNo: localStorage.getItem("regNo") });
            if (result.data.paymentStatus === "Pending") {
                navigate("/phdPayment");
                setspinner(false);
            };
            if (result.data.paymentStatus === "Success") {
                navigate("/dashboard");
                setspinner(false);
            };
        }
        catch (err) {
            console.log(err);
        }
    };


    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>

            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-4 mb-5' >
                    <Card style={{ width: "100%", padding: 16 }}>
                        <h3 style={{ textAlign: 'center', color: '#fd7e14', paddingTop: '10px' }}>
                            APPLICATION FOR ADMISSION TO Ph.D PROGRAM 2025 (Batch - I)</h3>
                        <Container>
                            <Row className="mt-3">
                                <Col><strong>Documents Upload</strong></Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <Form.Select onChange={docnameChange} name='documentName'>
                                        <option>Select Document Name</option>
                                        <option value="photo">Photo</option>
                                        <option value="H.S.C. Marksheet">H.S.C. Marksheet</option>
                                        <option value="UG Marksheet">UG Marksheet</option>
                                        <option value="PG Marksheet">PG Marksheet</option>
                                        <option value="UG Degree">UG Degree</option>
                                        <option value="PG Degree">PG Degree</option>
                                        <option value="UG Provisional">UG Provisional</option>
                                        <option value="PG Provisional">PG Provisional</option>
                                        <option value="M.Phil. Degree Certificate">M.Phil. Degree Certificate</option>
                                        <option value="Transfer Certificate">Transfer Certificate</option>
                                        <option value="Aadhar Card">Aadhar Card</option>
                                        <option value="Date of Birth Certificate">Date of Birth Certificate</option>
                                        <option value="Community Certificate">Community Certificate</option>
                                        <option value="Income Certificate">Income Certificate</option>
                                        <option value="Publication">Publication</option>
                                    </Form.Select>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <button onClick={handleUpload}>Upload</button>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col style={{ color: "red" }}>
                                    Note: Photo should be in jpg / jpeg / png format & Documents should be in pdf format.
                                </Col>
                            </Row>
                            {
                                uploadedDoc.map((data, index) => (
                                    <Row className="mt-3" key={data._id}>
                                        <Col xs={6} sm={3}>{index + 1}</Col>
                                        <Col xs={6} sm={3}>{data.documentName}</Col>
                                        <Col xs={6} sm={3}>
                                            <button style={{ marginRight: '10px', marginLeft: '100px' }}
                                                onClick={() => viewfile(data)}
                                            >view</button>
                                        </Col>
                                        <Col xs={6} sm={3}>
                                            <button onClick={() => deletefile(data)}>delete</button>
                                        </Col>
                                    </Row>
                                ))
                            }

                            <Row>
                                <Col >
                                    <Button variant="primary"
                                        onClick={goToPreviousPage}
                                        className="mt-3 w-70">
                                        Back
                                    </Button>
                                </Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col>
                                    <Button
                                        variant="primary"
                                        className="mt-3 w-70"
                                        onClick={nextPage}
                                    >
                                        Save & Next
                                    </Button>
                                </Col>
                            </Row>

                        </Container>
                    </Card>
                </Row>
            </Container>
        </>
    );
}

export default PhdDocumentsUpload