import React from 'react';
import { Button, Container, Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {
    savePhdApplication, getPhdApplicationCount,
    getPhdApplicationById, updatePhdApplication,
    getPhdApplicationByRegNo
} from './services';
import Spinner from './Spinner';

function getInitialState() {
    return {
        edit: false,
        registrationNature: '',
        applicantName: '',
        gender: '',
        dateofbirth: '',
        age: 0,
        community: '',
        religion: '',
        nationality: '',
        fatherName: '',
        residentialaddress: '',
        officeaddress: '',
        aadharNo: '',
        mobileNo: '',
        mailid: '',
        officialmailid: '',
        officialphoneno: '',
        userName: '',
        paymentStatus: '',
        applicationStatus: '',
        applicationNo: '',
        academicQualification: [
            {
                qualification: '',
                school_college: '',
                board_university: '',
                month_year_passing: '',
                subject: '',
                percentagemark: '',
                class_rank: ''
            }
        ],
        teachingExperience: [
            {
                teachExpDesignation: '',
                teachExpInstitution: '',
                teachExpFromDate: '',
                teachExpToDate: ''

            }
        ],
        researchExperience: [
            {
                researchExpDesignation: '',
                researchExpFundingAgency: '',
                researchExpFromDate: '',
                researchExpToDate: '',
                researchExpTheme: ''
            }
        ],
        uploadDocuments: [
            {
                documentName: '',
                filePath: ''
            }
        ],
        researchInterestArea: '',
        guideName1: '',
        department1: '',
        institute1: '',
        guideName2: '',
        department2: '',
        institute2: '',
        guideName3: '',
        department3: '',
        institute3: '',
        masterDegreeQualifiedSubject: '',
        degreeAwardedBy: '',
        othersDegreeAwardedBy: '',
        intends: '',
        mphilTopicDetails: '',
        creditsDetails: '',
        awardingAgency: '',
        periodOfValidity: '',
        ugcApprovedJournals: '',
        noOfPublications: 0,
        journalDetails: '',
        howKnowAboutTheProgramme: '',
        howKnowAboutIfOthers: '',
        submit: false,
        spinner: false,
        agree: false
    }
};

function PhdApplication() {
    const navigate = useNavigate();
    const [state, setState] = React.useState(getInitialState());
    const [file, Setfile] = React.useState();
    const [photoSrc, setPhotoSrc] = React.useState('');
    const regiNoBegin = 1000;

    const goToDashboard = () => {
        localStorage.removeItem("regNo");
        localStorage.removeItem("_id");
        navigate("/dashboard");
    };

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    React.useEffect(() => {
        const id = localStorage.getItem('_id');
        if (id) {
            getApplicationDetails(id);
        }
    }, []);

    async function getApplicationDetailsByRegNo(regNo) {
        const result = await getPhdApplicationByRegNo(regNo);
        console.log(result);
        setState(result.data);
        setState((prevState) => ({
            ...prevState,
            edit: true
        }));
    };

    React.useEffect(() => {
        const regNo = localStorage.getItem('regNo');
        if (regNo) {
            getApplicationDetailsByRegNo({ "regno": regNo });
        }
    }, []);

    async function getApplicationDetails(id) {
        const result = await getPhdApplicationById({ "_id": id });
        setState(result.data);
        setState((prevState) => ({
            ...prevState,
            edit: true
        }));
    };

    const onChangeInput = (event) => {
        const { target: { name, value, checked } } = event;

        setState((prevState) => ({
            ...prevState,
            [name]: name == 'agree' ? checked : value
        }));
    };

    const onChangeAcademicQualification = (index, event) => {
        const { target: { name, value } } = event;
        setState((prevState) => {
            const updateAcademicQualification = [...prevState.academicQualification];
            updateAcademicQualification[index][name] = value;

            return {
                ...prevState,
                academicQualification: updateAcademicQualification
            };
        });
    };

    const addNewRow = () => {
        const newRow = {
            qualification: '',
            school_college: '',
            board_university: '',
            month_year_passing: '',
            subject: '',
            percentagemark: '',
            class_rank: ''
        };

        setState((prevState) => ({
            ...prevState,
            academicQualification: [...prevState.academicQualification, newRow]
        }));
    };

    const deleteRow = (index) => {
        const deleteRow = [...state.academicQualification];
        deleteRow.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            academicQualification: deleteRow
        }));
    };

    const onChangeTeachingExperience = (index, event) => {
        const { target: { name, value } } = event;
        setState((prevState) => {
            const updateteachingExperience = [...prevState.teachingExperience];
            updateteachingExperience[index][name] = value;

            return {
                ...prevState,
                teachingExperience: updateteachingExperience
            };
        });
    };


    const addNewTeachingExperienceRow = () => {
        const newRow = {
            teachExpDesignation: '',
            teachExpInstitution: '',
            teachExpFromDate: '',
            teachExpToDate: ''
        };

        setState((prevState) => ({
            ...prevState,
            teachingExperience: [...prevState.teachingExperience, newRow]
        }));
    };

    const deleteTeachingExperienceRow = (index) => {
        const deleteRow = [...state.teachingExperience];
        deleteRow.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            teachingExperience: deleteRow
        }));
    };

    const onChangeResearchExperience = (index, event) => {
        const { target: { name, value } } = event;
        setState((prevState) => {
            const updateteresearchExperience = [...prevState.researchExperience];
            updateteresearchExperience[index][name] = value;

            return {
                ...prevState,
                researchExperience: updateteresearchExperience
            };
        });
    };

    const addNewResearchExperienceRow = () => {
        const newRow = {
            researchExpDesignation: '',
            researchExpFundingAgency: '',
            researchExpFromDate: '',
            researchExpToDate: '',
            researchExpTheme: ''
        };

        setState((prevState) => ({
            ...prevState,
            researchExperience: [...prevState.researchExperience, newRow]
        }));
    };

    const deleteResearchExperienceRow = (index) => {
        const deleteRow = [...state.researchExperience];
        deleteRow.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            researchExperience: deleteRow
        }));
    };

    const handlePhotoInputChange = (event) => {
        const { target: { files } } = event;
        if (!files[0]) {
            return;
        }

        Setfile(files[0]);
    };

    React.useEffect(() => {
        if (file) {
            setPhotoSrc(URL.createObjectURL(file));
        }
    }, [file]);

    React.useEffect(() => {
        getAgeAsOn31stDec();
    }, [state.dateofbirth]);

    function getAgeAsOn31stDec() {
        const dec31st = new Date(2024, 0, 18, 23, 59, 59, 999);
        const dobDate = new Date(state.dateofbirth);
        const diffInMs = dec31st.getTime() - dobDate.getTime();
        const diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365);
        const calculateAge = Math.floor(diffInYears);
        setState((prevState) => ({
            ...prevState,
            age: calculateAge
        }));
    };

    const SaveandNext = async () => {
        try {
            if (state.edit === false) {
                const count = await getPhdApplicationCount();
                setState((prevState) => ({
                    ...prevState,
                    userName: localStorage.getItem("username"),
                    paymentStatus: "Pending",
                    applicationStatus: "Pending",
                    applicationNo: `P${regiNoBegin + count.result + 1}`,
                    submit: true,
                    spinner: true
                }));
            };
            if (state.edit === true) {
                setState((prevState) => ({
                    ...prevState,
                    _id: localStorage.getItem('_id'),
                    submit: true,
                    spinner: true
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        if (state.submit) {
            if (state.edit === false) {
                save();
            };
            if (state.edit === true) {
                update();
            };
        }
    }, [state.submit]);

    const update = async () => {
        try {
            await updatePhdApplication(state);
            setState((prevState) => ({
                ...prevState,
                submit: false,
                spinner: false
            }));
            localStorage.setItem("regNo", state.applicationNo);
            navigate("/phdDocumentsUpload");
        }
        catch (err) {
            console.log(err);
        }
    };

    const save = async () => {
        const formData = new FormData();
        formData.append('details', JSON.stringify(state));
        formData.append('file', file);
        await savePhdApplication(formData);
        localStorage.setItem("regNo", state.applicationNo);
        setState((prevState) => ({
            ...prevState,
            submit: false,
            spinner: false
        }));
        navigate("/phdDocumentsUpload");
    };

    return (
        <>
            <div>
                {state.spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row className='mt-4' style={{ width: "100%" }}>
                    <Col>
                        <Button variant="primary" type="button"
                            onClick={goToDashboard}
                        >
                            Go To Dashboard
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                        <Button variant="primary" type="button"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-4 mb-5' >
                    <Card style={{ width: "100%", padding: 16 }}>
                        <Form>
                            <Row style={{ textAlign: "center", color: '#fd7e14' }}>
                                <Col><h4>APPLICATION FOR ADMISSION TO Ph.D PROGRAM 2025 (Batch - I)</h4></Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm={10}>
                                    <Row className='mt-4'>
                                        <Col xs={12} sm={6}><strong>Nature of Registration</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Check
                                                    inline
                                                    label="Full Time"
                                                    type="radio"
                                                    id="option-1"
                                                    name="registrationNature"
                                                    value="Full Time"
                                                    checked={state.registrationNature === 'Full Time'}
                                                    onChange={onChangeInput}
                                                >
                                                </Form.Check>

                                                <Form.Check
                                                    inline
                                                    label="Part- Time : Internal"
                                                    type="radio"
                                                    id="option-1"
                                                    name="registrationNature"
                                                    value="Part- Time : Internal"
                                                    checked={state.registrationNature === 'Part- Time : Internal'}
                                                    onChange={onChangeInput}
                                                >
                                                </Form.Check>
                                                <Form.Check
                                                    inline
                                                    label="Part- Time : External"
                                                    type="radio"
                                                    id="option-1"
                                                    name="registrationNature"
                                                    value="Part- Time : External"
                                                    checked={state.registrationNature === 'Part- Time : External'}
                                                    onChange={onChangeInput}
                                                >
                                                </Form.Check>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col xs={12} sm={6}><strong>Name of the Applicant</strong><br />
                                            <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(in P.G. Degree Certificate)</span>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                name='applicantName'
                                                value={state.applicantName}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={2}>
                                    <div style={{ width: "112px", height: "152px", border: "1px solid" }}>
                                        <img src={photoSrc} width={110} height={150} alt='photo'></img>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col><strong>Photo</strong></Col>
                                <Col>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handlePhotoInputChange}
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Gender</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="Male"
                                            type="radio"
                                            id="option-1"
                                            name="gender"
                                            value="Male"
                                            checked={state.gender === 'Male'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Female"
                                            type="radio"
                                            id="option-2"
                                            name="gender"
                                            value="Female"
                                            checked={state.gender === 'Female'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Third Gender"
                                            type="radio"
                                            id="option-2"
                                            name="gender"
                                            value="Third Gender"
                                            checked={state.gender === 'Third Gender'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Date of Birth</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='Date'
                                        name='dateofbirth'
                                        value={state.dateofbirth}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Age</strong><br />
                                    <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(as on 18 January 2024)</span>
                                </Col>
                                <Col xs={12} sm={6}>{state.age}</Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Community</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="SC"
                                            type="radio"
                                            name="community"
                                            value="SC"
                                            checked={state.community === "SC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="ST"
                                            type="radio"
                                            name="community"
                                            value="ST"
                                            checked={state.community === "ST"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="MBC"
                                            type="radio"
                                            name="community"
                                            value="MBC"
                                            checked={state.community === "MBC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="BC"
                                            type="radio"
                                            name="community"
                                            value="BC"
                                            checked={state.community === "BC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="OBC"
                                            type="radio"
                                            name="community"
                                            value="OBC"
                                            checked={state.community === "OBC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="OC"
                                            type="radio"
                                            name="community"
                                            value="OC"
                                            checked={state.community === "OC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Religion</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select name='religion' onChange={onChangeInput} value={state.religion}>
                                        <option value="">Select Religion</option>
                                        <option value="Hindu">Hindu</option>
                                        <option value="Muslim">Muslim</option>
                                        <option value="Christian">Christian</option>
                                        <option value="Buddhist">Buddhist</option>
                                        <option value="Jain">Jain</option>
                                        <option value="Sikh">Sikh</option>
                                        <option value="Others">Others</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Nationality</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='nationality'
                                        value={state.nationality}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Name of Father / Guardian</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='fatherName'
                                        value={state.fatherName}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Residential Address</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='residentialaddress'
                                        as="textarea"
                                        value={state.residentialaddress}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Office/Institution Address (Current Working)</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='officeaddress'
                                        as="textarea"
                                        value={state.officeaddress}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Aadhar No</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='aadharNo'
                                        value={state.aadharNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Mobile No</strong></Col>
                                <Col xs={12} sm={6}>
                                    <InputGroup>
                                        <InputGroup.Text id="mobile-prefix">+91</InputGroup.Text>
                                        <Form.Control
                                            name='mobileNo'
                                            value={state.mobileNo}
                                            type="tel"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            maxLength="10"
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </InputGroup>

                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Mail Id</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='email'
                                        name='mailid'
                                        value={state.mailid}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Official Mail Id</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='email'
                                        name='officialmailid'
                                        value={state.officialmailid}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Official Phone No</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='officialphoneno'
                                        value={state.officialphoneno}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col><strong>Academic Qualification (UG to M.Phil)</strong></Col>
                            </Row>

                            <Row className="mt-3" >
                                <Col>Qualification</Col>
                                <Col>School/College</Col>
                                <Col>Board/University</Col>
                                <Col>Month & Year of Passing</Col>
                                <Col>subject</Col>
                                <Col>% of Mark</Col>
                                <Col>Class/Rank</Col>
                                <Col></Col>
                            </Row>
                            {
                                state.academicQualification.map((row, index,) => (
                                    <Row className="mt-3" key={`${index}_phd`}>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='qualification'
                                                value={row.qualification}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='school_college'
                                                value={row.school_college}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='board_university'
                                                value={row.board_university}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='month_year_passing'
                                                value={row.month_year_passing}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='subject'
                                                value={row.subject}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='percentagemark'
                                                value={row.percentagemark}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='class_rank'
                                                value={row.class_rank}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            {
                                                (state.academicQualification.length - 1) === index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', width: '90px' }}
                                                        className="w-70"
                                                        onClick={addNewRow}
                                                    >
                                                        + Row
                                                    </Button>
                                                )
                                            }
                                            {
                                                (state.academicQualification.length - 1) !== index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', backgroundColor: 'red', width: '90px' }}
                                                        className="w-70"
                                                        onClick={() => deleteRow(index)}
                                                    >
                                                        - Row
                                                    </Button>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                ))
                            }

                            <Row className="mt-3">
                                <Col><strong>Professional/Teaching Experience</strong></Col>
                            </Row>

                            <Row className="mt-3" >
                                <Col>Designation</Col>
                                <Col>Institution</Col>
                                <Col>From Date</Col>
                                <Col>To Date</Col>
                                <Col></Col>
                            </Row>
                            {
                                state.teachingExperience.map((row, index) => (
                                    <Row className="mt-3" key={`${index}_teachexp`}>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='teachExpDesignation'
                                                value={row.teachExpDesignation}
                                                onChange={(event) => onChangeTeachingExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='teachExpInstitution'
                                                value={row.teachExpInstitution}
                                                onChange={(event) => onChangeTeachingExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='date'
                                                name='teachExpFromDate'
                                                value={row.teachExpFromDate}
                                                onChange={(event) => onChangeTeachingExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='date'
                                                name='teachExpToDate'
                                                value={row.teachExpToDate}
                                                onChange={(event) => onChangeTeachingExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            {
                                                (state.teachingExperience.length - 1) === index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', width: '130px' }}
                                                        className="w-70"
                                                        onClick={addNewTeachingExperienceRow}
                                                    >
                                                        Add New Row
                                                    </Button>
                                                )
                                            }
                                            {
                                                (state.teachingExperience.length - 1) !== index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', backgroundColor: 'red', width: '130px' }}
                                                        className="w-70"
                                                        onClick={() => deleteTeachingExperienceRow(index)}
                                                    >
                                                        Delete Row
                                                    </Button>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                ))
                            }

                            <Row className="mt-3">
                                <Col><strong>Research Experience</strong></Col>
                            </Row>

                            <Row className="mt-3" >
                                <Col>Designation</Col>
                                <Col>Funding Agency</Col>
                                <Col>From Date</Col>
                                <Col>To Date</Col>
                                <Col>Theme of Research</Col>
                                <Col></Col>
                            </Row>
                            {
                                state.researchExperience.map((row, index) => (
                                    <Row className="mt-3" key={`${index}_research_exp`}>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='researchExpDesignation'
                                                value={row.researchExpDesignation}
                                                onChange={(event) => onChangeResearchExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='researchExpFundingAgency'
                                                value={row.researchExpFundingAgency}
                                                onChange={(event) => onChangeResearchExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='date'
                                                name='researchExpFromDate'
                                                value={row.researchExpFromDate}
                                                onChange={(event) => onChangeResearchExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='date'
                                                name='researchExpToDate'
                                                value={row.researchExpToDate}
                                                onChange={(event) => onChangeResearchExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='researchExpTheme'
                                                value={row.researchExpTheme}
                                                onChange={(event) => onChangeResearchExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            {
                                                (state.researchExperience.length - 1) === index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', width: '130px' }}
                                                        className="w-70"
                                                        onClick={addNewResearchExperienceRow}
                                                    >
                                                        Add New Row
                                                    </Button>
                                                )
                                            }
                                            {
                                                (state.researchExperience.length - 1) !== index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', backgroundColor: 'red', width: '130px' }}
                                                        className="w-70"
                                                        onClick={() => deleteResearchExperienceRow(index)}
                                                    >
                                                        Delete Row
                                                    </Button>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                ))
                            }

                            <Row className='mt-4'>
                                <Col xs={12} sm={6}><strong>Mention the Specific Area of Research Interest / Department</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='researchInterestArea'
                                        value={state.researchInterestArea}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            {/*         <Row className="mt-3">
                                <Col><strong>Preference of the Guide (As per the Eligible Guide List of SBV)</strong></Col>
                            </Row>

                            <Row className="mt-3" style={{ textAlign: "center" }} >
                                <Col>Name of the Guide</Col>
                                <Col>Department</Col>
                                <Col>Institute</Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        name='guideName1'
                                        value={state.guideName1}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        name='department1'
                                        value={state.department1}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        name='institute1'
                                        value={state.institute1}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        name='guideName2'
                                        value={state.guideName2}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        name='department2'
                                        value={state.department2}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        name='institute2'
                                        value={state.institute2}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        name='guideName3'
                                        value={state.guideName3}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        name='department3'
                                        value={state.department3}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        name='institute3'
                                        value={state.institute3}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <p style={{ color: "blue" }}>* The allotment of Research Supervisor will be done by SBV depending on the availability of the Research Supervisor.</p>
                                </Col>
                            </Row> */}

                            <Row className='mt-4'>
                                <Col xs={12} sm={6}><strong>The subject in which the candidate has qualified for the master Degree</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='masterDegreeQualifiedSubject'
                                        value={state.masterDegreeQualifiedSubject}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>The above master's Degree was awarded by</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select name='degreeAwardedBy' onChange={onChangeInput} value={state.degreeAwardedBy}>
                                        <option value="">Please Select</option>
                                        <option value="Faculty of Management">Faculty of Management</option>
                                        <option value="Faculty of Engineering">Faculty of Engineering</option>
                                        <option value="Faculty of Dentistry">Faculty of Dentistry</option>
                                        <option value="Faculty of Nursing">Faculty of Nursing</option>
                                        <option value="Faculty of Science">Faculty of Science</option>
                                        <option value="Faculty of Allied Health Sciences">Faculty of Allied Health Sciences</option>
                                        <option value="Faculty of Medicine">Faculty of Medicine</option>
                                        <option value="Others">Others</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            {
                                state.degreeAwardedBy === "Others" &&
                                <Row className="mt-3">
                                    <Col xs={12} sm={6}><strong>If Others, Mention degree awarded by</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='othersDegreeAwardedBy'
                                            value={state.othersDegreeAwardedBy}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }

                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Proposed Discipline in which the Candidate intends to work for Ph.D.</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="Faculty of Medicine"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Medicine"
                                            checked={state.intends === "Faculty of Medicine"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Faculty of Pharmacy"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Pharmacy"
                                            checked={state.intends === "Faculty of Pharmacy"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Faculty of Dentistry"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Dentistry"
                                            checked={state.intends === "Faculty of Dentistry"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        {/*      <Form.Check
                                            inline
                                            label="Faculty of Nursing Sciences"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Nursing Sciences"
                                            checked={state.intends === "Faculty of Nursing Sciences"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check> */}
                                        <Form.Check
                                            inline
                                            label="Faculty of Biomedical Sciences"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Biomedical Sciences"
                                            checked={state.intends === "Faculty of Biomedical Sciences"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Faculty of Physiotherapy"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Physiotherapy"
                                            checked={state.intends === "Faculty of Physiotherapy"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        {/*       <Form.Check
                                            inline
                                            label="Faculty of Health Professions Education"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Health Professions Education"
                                            checked={state.intends === "Faculty of Health Professions Education"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check> */}

                                        <Form.Check
                                            inline
                                            label="Faculty of Allied Health Sciences"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Allied Health Sciences"
                                            checked={state.intends === "Faculty of Allied Health Sciences"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Faculty of Multidisciplinary Research"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Multidisciplinary Research"
                                            checked={state.intends === "Faculty of Multidisciplinary Research"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Faculty of Yoga Therapy"
                                            type="radio"
                                            name="intends"
                                            value="Faculty of Yoga Therapy"
                                            checked={state.intends === "Faculty of Yoga Therapy"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col><strong>For candidates who have qualified with M.Phil.</strong></Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}>1. Details of the papers with their topics in M.Phil.</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='mphilTopicDetails'
                                        as="textarea"
                                        value={state.mphilTopicDetails}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}>2. Details of the Credits for each paper.</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='creditsDetails'
                                        as="textarea"
                                        value={state.creditsDetails}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col><strong>Details on award of Junior Research Fellowship</strong></Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}>1. Awarding agency</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='awardingAgency'
                                        value={state.awardingAgency}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}>2. Period of validity <br />
                                    <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(To enclose the valid certificate)</span>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='periodOfValidity'
                                        value={state.periodOfValidity}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Research Publications if any in UGC approved journals</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            type="radio"
                                            name="ugcApprovedJournals"
                                            value="Yes"
                                            checked={state.ugcApprovedJournals === "Yes"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="No"
                                            type="radio"
                                            name="ugcApprovedJournals"
                                            value="No"
                                            checked={state.ugcApprovedJournals === "No"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                state.ugcApprovedJournals === "Yes" &&
                                <div>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}><strong>No. of Publications</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='number'
                                                name='noOfPublications'
                                                value={state.noOfPublications}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}><strong>Details of the Journals</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                name='journalDetails'
                                                as="textarea"
                                                value={state.journalDetails}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </div>
                            }

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>How did you come to know about the Ph.D. Program in Sri Balaji Vidyapeeth?</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select name='howKnowAboutTheProgramme' onChange={onChangeInput} value={state.howKnowAboutTheProgramme}>
                                        <option value="">Select</option>
                                        <option value="SBV Website">SBV Website</option>
                                        <option value="SBV Faculty">SBV Faculty</option>
                                        <option value="Newspaper Advertisement">Newspaper Advertisement</option>
                                        <option value="Social Media">Social Media</option>
                                        <option value="HelpBiotech">HelpBiotech</option>
                                        <option value="IndiaBioscience">IndiaBioscience</option>
                                        <option value="Others">Others</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            {state.howKnowAboutTheProgramme === "Others" &&
                                <Row className="mt-3">
                                    <Col xs={12} sm={6}><strong>If Others</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='howKnowAboutIfOthers'
                                            value={state.howKnowAboutIfOthers}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }

                            <Row className='mt-3'>
                                <Col>
                                    <Form.Check
                                        type="checkbox"
                                        id="myCheckbox"
                                        name='agree'
                                        checked={state.agree}
                                        onChange={onChangeInput}
                                        label={
                                            <div>
                                                <span>
                                                    <strong>
                                                        I State that I had fully gone through the PhD regulations of SBV uploaded in SBV website. I also state that all the detailes filled in the application form are correct.
                                                    </strong>
                                                </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col><strong>Note:</strong></Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col style={{ marginLeft: "35px" }}>
                                    Admission will be based on the online assessment followed by counseling to be held at the Sri Balaji Vidyapeeth Campus,
                                    Pillaiyarkuppam, Podnicherry - 607 402.<br />
                                    The allotment of Research Supervisor will be done by SBV depending on the availability of the Research Supervisor.
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col><strong>Instruction to upload Publications:</strong></Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col style={{ marginLeft: "35px" }}>
                                    <strong>Applicants are directed to attach the list of their publications in the Vancouver
                                        format (Example below).
                                    </strong>
                                    <br />
                                    Stephen S, Kandhakumari G, Pradeep J, Vinithra SM, Siva PK, Hanifah M, et al. Scrub typhus in south India:
                                    a re-emerging infectious disease. Jpn J Infect Dis. 2013;66: 552–4.
                                    <br />
                                    Indexed in : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                                        <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                                        <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                    </svg> &nbsp;Scopus
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                                        <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z" />
                                    </svg>&nbsp;Web of Science
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                                        <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z" />
                                    </svg>&nbsp;PubMed
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                                        <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z" />
                                    </svg>&nbsp;UGC CARE.
                                    <br />
                                    Thomson Reuter / web of Science <strong>Impact Factor: 2.22 (TR).</strong>
                                </Col>
                            </Row>
                            {
                                state.agree === true &&
                                <Row className='mt-3'>
                                    <Col style={{ textAlign: "center" }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            style={{ alignItems: 'center', width: '150px' }}
                                            className="w-70"
                                            onClick={SaveandNext}
                                        >
                                            Save & Next
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default PhdApplication